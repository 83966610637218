<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0001 4C8.06709 4 6.50008 5.567 6.50008 7.5C6.50008 9.433 8.06709 11 10.0001 11C11.9331 11 13.5001 9.433 13.5001 7.5C13.5001 5.567 11.9331 4 10.0001 4ZM4.50008 7.5C4.50008 4.46243 6.96252 2 10.0001 2C13.0376 2 15.5001 4.46243 15.5001 7.5C15.5001 10.5376 13.0376 13 10.0001 13C6.96252 13 4.50008 10.5376 4.50008 7.5ZM19.0001 14C19.5524 14 20.0001 14.4477 20.0001 15V17H22.0001C22.5524 17 23.0001 17.4477 23.0001 18C23.0001 18.5523 22.5524 19 22.0001 19H20.0001V21C20.0001 21.5523 19.5524 22 19.0001 22C18.4478 22 18.0001 21.5523 18.0001 21V19H16.0001C15.4478 19 15.0001 18.5523 15.0001 18C15.0001 17.4477 15.4478 17 16.0001 17H18.0001V15C18.0001 14.4477 18.4478 14 19.0001 14ZM7.32635 14.5C7.38341 14.5 7.44132 14.5 7.50009 14.5H12.0001C12.5524 14.5 13.0001 14.9477 13.0001 15.5C13.0001 16.0523 12.5524 16.5 12.0001 16.5H7.50009C6.03177 16.5 5.51929 16.5109 5.12923 16.6292C4.17042 16.92 3.42011 17.6703 3.12926 18.6291C3.01094 19.0192 3.00008 19.5317 3.00008 21C3.00008 21.5523 2.55237 22 2.00008 22C1.4478 22 1.00008 21.5523 1.00008 21C1.00008 20.9412 1.00006 20.8833 1.00004 20.8263C0.999587 19.599 0.999277 18.761 1.21538 18.0486C1.70013 16.4506 2.95065 15.2 4.54866 14.7153C5.26106 14.4992 6.09908 14.4995 7.32635 14.5Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
