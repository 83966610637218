<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.08814 3.28953C9.82924 0.23682 14.1708 0.236825 14.9119 3.28953C15.0391 3.81369 15.6396 4.06243 16.1002 3.78177C18.7829 2.14722 21.8528 5.21714 20.2182 7.89977C19.9376 8.36037 20.1863 8.96089 20.7105 9.08814C23.7632 9.82924 23.7632 14.1708 20.7105 14.9119C20.1863 15.0391 19.9376 15.6396 20.2182 16.1002C21.8528 18.7829 18.7829 21.8528 16.1002 20.2182C15.6396 19.9376 15.0391 20.1863 14.9119 20.7105C14.1708 23.7632 9.82924 23.7632 9.08814 20.7105C8.96089 20.1863 8.36038 19.9376 7.89977 20.2182C5.21714 21.8528 2.14722 18.7829 3.78177 16.1002C4.06243 15.6396 3.81369 15.0391 3.28953 14.9119C0.236825 14.1708 0.23682 9.82924 3.28953 9.08814C3.81369 8.96089 4.06243 8.36037 3.78177 7.89977C2.14722 5.21714 5.21714 2.14722 7.89977 3.78177C8.36038 4.06243 8.96089 3.81368 9.08814 3.28953ZM12.774 3.80855C12.577 2.99715 11.423 2.99715 11.226 3.80855C10.7473 5.78056 8.48799 6.7164 6.75504 5.6605C6.04201 5.22604 5.22604 6.04201 5.6605 6.75504C6.7164 8.48799 5.78056 10.7473 3.80855 11.226C2.99715 11.423 2.99715 12.577 3.80855 12.774C5.78056 13.2527 6.7164 15.512 5.6605 17.245C5.22604 17.958 6.04201 18.774 6.75504 18.3395C8.48799 17.2836 10.7473 18.2194 11.226 20.1915C11.423 21.0028 12.577 21.0029 12.774 20.1915C13.2527 18.2194 15.512 17.2836 17.245 18.3395C17.958 18.774 18.774 17.958 18.3395 17.245C17.2836 15.512 18.2194 13.2527 20.1915 12.774C21.0029 12.577 21.0028 11.423 20.1915 11.226C18.2194 10.7473 17.2836 8.48799 18.3395 6.75504C18.774 6.04201 17.958 5.22604 17.245 5.6605C15.512 6.7164 13.2527 5.78056 12.774 3.80855ZM12 9.8C10.785 9.8 9.8 10.785 9.8 12C9.8 13.215 10.785 14.2 12 14.2C13.215 14.2 14.2 13.215 14.2 12C14.2 10.785 13.215 9.8 12 9.8ZM7.6 12C7.6 9.56995 9.56995 7.6 12 7.6C14.4301 7.6 16.4 9.56995 16.4 12C16.4 14.4301 14.4301 16.4 12 16.4C9.56995 16.4 7.6 14.4301 7.6 12Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
