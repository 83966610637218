<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2380_57588)">
      <ellipse
        cx="11.3576"
        cy="11.4431"
        rx="9.28092"
        ry="4.3311"
        transform="rotate(-30 11.3576 11.4431)"
        stroke="#191414"
        stroke-width="1.1"
      />
      <ellipse
        cx="11.8484"
        cy="12.2928"
        rx="11.3841"
        ry="5.31256"
        transform="rotate(-30 11.8484 12.2928)"
        stroke="#191414"
        stroke-width="1.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_2380_57588">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
