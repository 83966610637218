<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3544 1.77566C11.7628 1.58096 12.2372 1.58096 12.6456 1.77566C12.9986 1.94398 13.1932 2.23622 13.2914 2.39654C13.3928 2.56228 13.497 2.77342 13.5985 2.97921C13.6037 2.98972 13.6089 3.00022 13.6141 3.01069L15.7543 7.34648L20.5763 8.05129C20.8033 8.08442 21.0362 8.11841 21.2251 8.16384C21.4079 8.20779 21.7458 8.30284 22.0147 8.58668C22.3258 8.91505 22.4721 9.36626 22.4129 9.8147C22.3617 10.2023 22.1439 10.4776 22.0216 10.6204C21.8953 10.768 21.7267 10.9322 21.5624 11.0922L18.0745 14.4894L18.8975 19.2876C18.9363 19.5139 18.9762 19.746 18.9915 19.9398C19.0064 20.1272 19.0207 20.4781 18.834 20.8218C18.6181 21.2194 18.2342 21.4983 17.7893 21.5807C17.4047 21.652 17.0754 21.5299 16.9018 21.4579C16.7222 21.3834 16.5138 21.2737 16.3107 21.1668L12 18.8999L7.68933 21.1668C7.48618 21.2737 7.27776 21.3834 7.09821 21.4579C6.92457 21.5299 6.59529 21.652 6.21069 21.5807C5.76582 21.4983 5.38194 21.2194 5.16598 20.8218C4.97928 20.4781 4.99363 20.1272 5.00847 19.9398C5.02381 19.746 5.06367 19.5139 5.10252 19.2876L5.92548 14.4894L2.46281 11.1167C2.45445 11.1086 2.44608 11.1004 2.43769 11.0923C2.27332 10.9322 2.10467 10.768 1.97835 10.6204C1.85615 10.4776 1.63831 10.2023 1.58712 9.8147C1.5279 9.36626 1.6742 8.91505 1.98529 8.58668C2.2542 8.30284 2.59211 8.20779 2.77487 8.16384C2.96379 8.11841 3.19671 8.08442 3.4237 8.05129C3.43529 8.0496 3.44685 8.04791 3.4584 8.04622L8.24574 7.34648L10.3859 3.01069C10.3911 3.00022 10.3963 2.98972 10.4015 2.97921C10.503 2.77342 10.6072 2.56228 10.7086 2.39654C10.8068 2.23622 11.0014 1.94398 11.3544 1.77566Z"
      fill="#FFC531"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
