<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.28544 12.7208C4.54877 13.9842 6.2221 14.6795 7.99944 14.6795C9.77677 14.6795 11.4508 13.9842 12.7134 12.7208C15.3128 10.1215 15.3128 5.89216 12.7134 3.29216C11.4508 2.0295 9.7761 1.3335 7.99944 1.3335C6.22277 1.3335 4.5481 2.0295 3.28544 3.29216C0.686104 5.8915 0.686104 10.1215 3.28544 12.7208ZM11.7708 11.7782C11.0721 12.4787 10.1917 12.9703 9.22877 13.1975C9.22277 12.1295 9.61944 11.1002 10.3561 10.3635C11.1314 9.58816 12.1774 9.14483 13.2074 9.1295C12.9951 10.1329 12.496 11.053 11.7708 11.7782ZM13.3208 7.8015C11.9048 7.7875 10.4648 8.37016 9.41344 9.42083C8.3921 10.4422 7.8621 11.8742 7.90477 13.3415C6.52191 13.3204 5.20214 12.7593 4.22744 11.7782C3.25152 10.8018 2.69394 9.48384 2.67277 8.1035C2.7241 8.10483 2.77544 8.11283 2.82677 8.11283C4.23677 8.11283 5.6001 7.57683 6.58477 6.59283C7.6381 5.5395 8.22277 4.10016 8.20477 2.6775C9.54802 2.72574 10.8227 3.28267 11.7708 4.2355C12.72 5.18525 13.2739 6.45954 13.3208 7.8015ZM4.2281 4.2355C4.95264 3.50758 5.87266 3.00531 6.87677 2.7895C6.86477 3.82616 6.4201 4.87216 5.64277 5.65016C4.90677 6.3855 3.88144 6.78083 2.81744 6.7775C3.04083 5.81497 3.52954 4.93433 4.2281 4.2355Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
