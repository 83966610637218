<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C10.067 4 8.5 5.567 8.5 7.5C8.5 9.433 10.067 11 12 11C13.933 11 15.5 9.433 15.5 7.5C15.5 5.567 13.933 4 12 4ZM6.5 7.5C6.5 4.46243 8.96244 2 12 2C15.0376 2 17.5 4.46243 17.5 7.5C17.5 10.5376 15.0376 13 12 13C8.96244 13 6.5 10.5376 6.5 7.5ZM12 17C8.8088 17 5.89333 18.389 3.7276 20.686C3.34873 21.0878 2.71584 21.1065 2.314 20.7276C1.91216 20.3487 1.89353 19.7158 2.2724 19.314C4.77826 16.6562 8.20523 15 12 15C15.7948 15 19.2217 16.6562 21.7276 19.314C22.1065 19.7158 22.0878 20.3487 21.686 20.7276C21.2842 21.1065 20.6513 21.0878 20.2724 20.686C18.1067 18.389 15.1912 17 12 17Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
