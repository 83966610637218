<template>
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0702 1.52145L6.35328 7.60372C5.96335 8.24179 5.03665 8.24179 4.64672 7.60372L0.929775 1.52145C0.522559 0.855097 1.00213 0 1.78306 0H9.21694C9.99787 0 10.4774 0.855097 10.0702 1.52145Z"
      fill="#FF2E2D"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
