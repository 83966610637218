<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8083 12.6205C15.2867 11.8646 13.5528 11.5815 11.9959 11.9977C11.4368 14.0798 9.63918 15.8165 7.5401 16.6559M13.7043 2.1463C11.9034 1.83483 10.0516 2.02283 8.3501 2.68989C6.64858 3.35695 5.1624 4.47755 4.05303 5.92995C2.94366 7.38234 2.25353 9.11099 2.05762 10.9281C1.86172 12.7451 2.16752 14.5812 2.94181 16.2366C3.71611 17.8921 4.92927 19.3038 6.44951 20.3182C7.96975 21.3326 9.73891 21.911 11.5648 21.9905C13.3907 22.0701 15.2034 21.6477 16.8061 20.7693C18.4088 19.891 19.7401 18.5902 20.6555 17.0083V17.0083C21.4614 15.6136 21.9186 14.0448 21.9882 12.4355C22.0578 10.8262 21.7378 9.22383 21.0553 7.7647C20.3729 6.30558 19.3482 5.03282 18.0683 4.05465C16.7885 3.07647 15.2914 2.4218 13.7043 2.1463V2.1463Z"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M7.54023 16.6557C5.02077 12.9529 4.49706 8.17445 5.96911 4.02441"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M18.3313 13.6113C16.378 17.6297 12.511 20.4606 8.19116 21.2546"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M10.2053 5.76568C14.6413 5.41324 19.0192 7.29859 21.8968 10.5895"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M19.7117 18.3657H19.71"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M11.9945 12.0046C9.49767 9.50641 9.47219 4.52835 11.9945 2.00464"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M11.9945 12.0046C11.4369 14.0797 9.63928 15.8164 7.5402 16.6557C6.17856 17.2007 4.69094 17.3634 3.33496 17.0039"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />

    <path
      d="M11.9946 12.0076C13.5516 11.5915 15.2869 11.8746 16.8071 12.6304C18.6344 13.5377 20.1489 15.1272 20.6542 17.0069"
      stroke="#191414"
      stroke-width="1.6"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
