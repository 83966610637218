<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.29289 0.894456C8.68342 0.503931 9.31658 0.503931 9.70711 0.894456L11.7071 2.89446C12.0976 3.28498 12.0976 3.91814 11.7071 4.30867L9.70711 6.30867C9.31658 6.69919 8.68342 6.69919 8.29289 6.30867C7.90237 5.91815 7.90237 5.28498 8.29289 4.89446L8.54469 4.64266C5.09909 5.3202 2.5 8.35735 2.5 12.0016C2.5 14.494 3.71475 16.7025 5.58885 18.0679C6.03523 18.3931 6.13345 19.0186 5.80824 19.465C5.48302 19.9114 4.85752 20.0096 4.41114 19.6844C2.04258 17.9587 0.5 15.1602 0.5 12.0016C0.5 7.23406 4.01184 3.28679 8.58974 2.60552L8.29289 2.30867C7.90237 1.91815 7.90237 1.28498 8.29289 0.894456ZM14.1918 4.53811C14.517 4.09173 15.1425 3.99351 15.5889 4.31872C17.9574 6.04438 19.5 8.84288 19.5 12.0016C19.5 16.7691 15.9882 20.7163 11.4103 21.3976L11.7071 21.6945C12.0976 22.085 12.0976 22.7181 11.7071 23.1087C11.3166 23.4992 10.6834 23.4992 10.2929 23.1087L8.29289 21.1087C7.90237 20.7181 7.90237 20.085 8.29289 19.6945L10.2929 17.6945C10.6834 17.3039 11.3166 17.3039 11.7071 17.6945C12.0976 18.085 12.0976 18.7181 11.7071 19.1087L11.4553 19.3605C14.9009 18.6829 17.5 15.6458 17.5 12.0016C17.5 9.50917 16.2853 7.30061 14.4111 5.9352C13.9648 5.60998 13.8665 4.98448 14.1918 4.53811Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
