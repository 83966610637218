<template>
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.929773 7.47855L4.64672 1.39628C5.03665 0.758213 5.96335 0.758212 6.35328 1.39628L10.0702 7.47855C10.4774 8.1449 9.99787 9 9.21694 9L1.78306 9C1.00213 9 0.522557 8.1449 0.929773 7.47855Z"
      fill="#00BC57"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
