<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.63604 4.22185C6.02656 4.61237 6.02656 5.24554 5.63604 5.63606C2.12132 9.15078 2.12132 14.8493 5.63604 18.364C6.02656 18.7545 6.02656 19.3877 5.63604 19.7782C5.24551 20.1687 4.61235 20.1687 4.22183 19.7782C-0.0739417 15.4824 -0.0739417 8.51762 4.22183 4.22185C4.61235 3.83132 5.24551 3.83132 5.63604 4.22185ZM18.364 4.22189C18.7545 3.83137 19.3876 3.83137 19.7782 4.22189C24.0739 8.51766 24.0739 15.4825 19.7782 19.7782C19.3876 20.1688 18.7545 20.1688 18.364 19.7782C17.9734 19.3877 17.9734 18.7546 18.364 18.364C21.8787 14.8493 21.8787 9.15083 18.364 5.63611C17.9734 5.24558 17.9734 4.61242 18.364 4.22189ZM8.46447 7.05024C8.85499 7.44076 8.85499 8.07393 8.46447 8.46445C6.51184 10.4171 6.51184 13.5829 8.46447 15.5355C8.85499 15.926 8.85499 16.5592 8.46447 16.9497C8.07394 17.3403 7.44078 17.3403 7.05025 16.9497C4.31658 14.2161 4.31658 9.78391 7.05025 7.05024C7.44078 6.65972 8.07394 6.65972 8.46447 7.05024ZM15.5355 7.05028C15.9261 6.65975 16.5592 6.65975 16.9497 7.05028C19.6834 9.78395 19.6834 14.2161 16.9497 16.9498C16.5592 17.3403 15.9261 17.3403 15.5355 16.9498C15.145 16.5592 15.145 15.9261 15.5355 15.5356C17.4882 13.5829 17.4882 10.4171 15.5355 8.46449C15.145 8.07396 15.145 7.4408 15.5355 7.05028ZM12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11ZM9 12C9 10.3432 10.3431 9.00002 12 9.00002C13.6569 9.00002 15 10.3432 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
