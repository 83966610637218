<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0005 3.51468C14.3436 1.17154 18.1426 1.17154 20.4857 3.51468C22.8289 5.85783 22.8289 9.65682 20.4857 12L19.0715 13.4142C18.681 13.8047 18.0478 13.8047 17.6573 13.4142C17.2668 13.0237 17.2668 12.3905 17.6573 12L19.0715 10.5858C20.6336 9.02365 20.6336 6.49099 19.0715 4.9289C17.5094 3.3668 14.9768 3.3668 13.4147 4.9289L12.0005 6.34311C11.6099 6.73363 10.9768 6.73363 10.5862 6.34311C10.1957 5.95259 10.1957 5.31942 10.5862 4.9289L12.0005 3.51468ZM16.2076 7.79284C16.5981 8.18336 16.5981 8.81653 16.2076 9.20705L9.20756 16.207C8.81704 16.5976 8.18387 16.5976 7.79335 16.207C7.40282 15.8165 7.40282 15.1834 7.79335 14.7928L14.7933 7.79284C15.1839 7.40231 15.817 7.40231 16.2076 7.79284ZM6.3436 10.5858C6.73412 10.9763 6.73412 11.6094 6.3436 12L4.92939 13.4142C3.36729 14.9763 3.36729 17.5089 4.92939 19.071C6.49148 20.6331 9.02414 20.6331 10.5862 19.071L12.0005 17.6568C12.391 17.2663 13.0241 17.2663 13.4147 17.6568C13.8052 18.0473 13.8052 18.6805 13.4147 19.071L12.0005 20.4852C9.65731 22.8284 5.85832 22.8284 3.51517 20.4852C1.17203 18.1421 1.17203 14.3431 3.51517 12L4.92939 10.5858C5.31991 10.1952 5.95307 10.1952 6.3436 10.5858Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
