<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_112_1128)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 0V6.006H5.979V2.54L0 8.012L5.979 13.473V9.995H7V16H7.02H10.981V9.994H12.039V13.461L18 7.988L12.039 2.527V6.005H10.981V0H7Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3402 7.98806L13.0202 4.76506V7.01906H10.0002V1.05906H9.98116V1.03906H8.04016V7.03906H4.98016V4.75906L1.66016 8.01006L4.98016 11.2341V8.98006H8.04016V14.9801H8.06016H9.98116H10.0002V8.98006H13.0202V11.2391L16.3402 7.98806Z"
        fill="#191414"
      />
    </g>
    <defs>
      <clipPath id="clip0_112_1128">
        <rect
          width="18"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
