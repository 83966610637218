<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C9.78959 4 7.79015 4.89514 6.34119 6.34511C5.58346 7.10335 4.72302 8.13128 4.03079 9H8C8.55228 9 9 9.44772 9 10C9 10.5523 8.55228 11 8 11H2C1.44772 11 1 10.5523 1 10V4C1 3.44772 1.44772 3 2 3C2.55228 3 3 3.44772 3 4V7.09444C3.60188 6.36248 4.28685 5.57146 4.92649 4.93138C6.73522 3.12139 9.2376 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C7.43955 22 3.59451 18.9481 2.39097 14.7773C2.23785 14.2466 2.54388 13.6923 3.07452 13.5392C3.60515 13.3861 4.15944 13.6921 4.31256 14.2227C5.27571 17.5605 8.35426 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
